.boxes {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3.5px;

  position: absolute;
  width: 501px;
  height: 501px;
}

.box {
  width: 160px;
  height: 160px;
  cursor: pointer;
}

.svg-board {
  stroke: rgb(0, 0, 0);
  width: 501px;
  height: 501px;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.line {
  stroke: white;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1.25s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.circle {
  stroke-width: 12.5;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  transition: stroke 0.5s ease-out;
}

.cross {
  stroke-width: 12.5;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  transition: stroke 0.5s ease-out;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@media (max-width: 768px) {
  .boxes {
    grid-gap: 2px;
    width: 364px;
    height: 364px;
  }

  .box {
    width: 120px;
    height: 120px;
    cursor: pointer;
  }

  .svg-board {
    width: 364px;
    height: 364px;
  }
}

@media (max-width: 481px) {
  .boxes {
    grid-gap: 1px;
    width: 242px;
    height: 242px;
  }

  .box {
    width: 80px;
    height: 80px;
    cursor: pointer;
  }

  .svg-board {
    width: 242px;
    height: 242px;
  }

  .line {
    stroke-width: 5;
  }

  .circle {
    stroke-width: 7.5;
  }

  .cross {
    stroke-width: 7.5;
  }
}
