.game {
  height: 100vh;
  width: 100vw;
  color: white;
  text-align: center;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transition: background-color 1s ease-out;
}
