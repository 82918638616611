.score-board {
  position: absolute;
  top: 0;
  width: 125px;
  height: 125px;
  cursor: default;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.score-board p {
  font-size: 96px;
  padding-bottom: 7.5px;
  margin: 0 auto;
}

.left-score {
  justify-content: flex-start;
  border-bottom-right-radius: 50%;
  left: 0;
  transition: background-color 0.5s ease-out;
}

.right-score {
  justify-content: flex-end;
  border-bottom-left-radius: 50%;
  right: 0;
  transition: background-color 0.5s ease-out;
}

@media (max-width: 481px) {
  .score-board {
    width: 100px;
    height: 100px;
  }

  .score-board p {
    font-size: 72px;
  }
}
