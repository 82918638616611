@import "./theme.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
}

.home {
  height: 100vh;
  width: 100vw;
  background-color: #044d6e;
}
